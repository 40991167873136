import * as React from 'react'
import Layout from 'components/Layout'
import Seo from 'components/seo'
import {
    Stack,
    Container,
    FormControl,
    Button,
    TextField,
    Box
} from "@mui/material";

import AppealBanner from 'assets/banner/ban_appeal.png'
import Links from 'components/Links'
import BunfanTextLogo from "assets/logos/bunfan-text-logo.jpg";



const Index = () => (
    <Layout>
        <Seo
            title="Ban Appeals"
            description="Discord Ban Appeal Form 🚀"
            image={BunfanTextLogo}
        />
        <Stack
            alignItems="center"
            justifyContent="center"
            marginBottom={30}
            marginTop={5}
            spacing={3}
        >
            <img src={AppealBanner} style={{ maxWidth: 600, borderRadius: 10 }} />
            <AppealForm />
        </Stack>
        <Links />
    </Layout >
)

const AppealInfo = () => (
    <Box component="section" sx={{ p: 4, border: '3px dotted #555', marginBottom: 3 }}>
        <p style={{ fontSize: 16 }}>
            Once you've filled out the form a staff member will review the details of your appeal.
            If your ban is appealed, a moderator will reach out to you via direct message over Discord.
            Please note that we receive lots of ban appeal requests, so it may take several days to receive a response.
            Submitting the form multiple times will not speed up the process.
            <br /><br />
            Thanks for your cooperation and understanding!
        </p>
    </Box>
)


const AppealForm = () => {

    return (
        <Container maxWidth="sm">
            <Stack
                alignItems="center"
                justifyContent="center"
            >
                <form onSubmit={handleSubmit}>
                    <FormControl
                        required
                    >

                        <TextField style={{ width: 600 }} placeholder="Username" id="outlined-basic" label="Discord Username" variant="outlined" required /><br />
                        <TextField placeholder="12345678910" id="outlined-basic" label="Discord Id" variant="outlined" required /><br />
                        <TextField multiline rows={10} placeholder="For what reason are you appealing your ban?" id="outlined-basic" label="Appeal Reason" variant="outlined" required /><br />
                        <AppealInfo />
                        <Button
                            type='submit'
                            variant="outlined"
                        >
                            Submit Appeal 🚀
                        </Button>
                    </FormControl>
                </form>
            </Stack>
        </Container>
    )
}

const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const username = e.target[0].value
    const discordId = e.target[2].value
    const appealReason = e.target[4].value

    if (isNaN(discordId)) return alert('Discord Id must be a number 😔')

    const currentTimestamp = new Date()
    const webhookUrl = "https://discord.com/api/webhooks/1229535880545304678/o9X-5JGbStcHakAYGQRkzRTdSNYpTcC51GWJrICg7X1dCFMlA2OEArp0-G7RzuyCWTNT"
    const body = {
        content: "<@&789267614903959583>",
        embeds: [
            {
                description: `A new appeal has been submitted by **${username}**! 📕`,
                color: 2326507,
                fields: [
                    {
                        "name": "User ID",
                        "value": `${discordId}`
                    },
                    {
                        "name": "Appeal Reason",
                        "value": `${appealReason}`
                    }
                ],
                author: {
                    name: `🙏 ${username}`
                },
                timestamp: currentTimestamp.toISOString(),
                footer: {
                    "text": "Ban Appeals"
                }
            }
        ]
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };


    fetch(webhookUrl, requestOptions)
        .then(() => {
            alert('Form Successfully Submitted ✨')
            document.location.href = "/";

        })
        .catch(err => {
            alert(`Error submitting form ${err}`)
        })


}


export default Index


